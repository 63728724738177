

<template>
  <v-container
    id="forgot-password"
    class="fill-height justify-center"
    tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>

    <v-row justify="center" v-show="!globalLoading">
      <v-col cols="12" class="text-center">
        <h1>
          <span class="text-h1">M360API</span> &reg;<br/>
          <span class="text-h2">API Management Platform</span>
        </h1>
      </v-col>
    </v-row>

    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Forgot your password?
              </h1>
            </div>
          </template>
          <form @submit="checkForm" novalidate="true" v-if="!isSubmitted">
            <v-card-text class="text-center">

              <v-text-field
                color="secondary"
                label="Email..."
                prepend-icon="mdi-email"
                v-model="username"
              />

              <pages-btn
                large
                color=""
                depressed
                class="v-btn--text info--text"
                @click="checkForm"
              >
                <v-icon class="mr-1">mdi-lock</v-icon>
                Reset password
              </pages-btn>
            </v-card-text>
          </form>
          <v-card-text v-if="isSubmitted" class="text-center">
            We sent you an email with the <strong>reset password</strong> instructions.
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>

import globalMixins from "../../mixins/globalMixins";

export default {
  name: 'PagesForgotPassword',

  mixins: [globalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    username: '',
    isSubmitted: false,
  }),

  created() {
    this.clearMessages();
    this.redirectIfAuthenticated();
  },

  methods: {
    checkForm: function (e) {
      if (!this.username) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide an email address.'
        });
        return false;
      }

      if (!this.username) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid username or email address.'
        });
        return false;
      }

      this.submit();
      e.preventDefault();
    },

    /* eslint-disable */
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },

    /* eslint-enable */
    submit() {
      const self = this;
      self.getSendData({
        'url': '/user/password/forgot',
        'method': 'post',
        'headers': {
          'env': 'portal'
        },
        'params': {
          "auto_register_flow": "corsair_saas",
          'username': self.username
        }
      }).then(() => {
        self.isSubmitted = true;
      }).catch(() => {
        // already handled in getSendData
      });
    }
  },
}
</script>
